<template>
  <div class="OrderIdiomS">
    <div class="title_box">
      <div class="title_txt">
        成语券消费
        <div class="type">
          <div
            class="item"
            @click="reTypeCLick(item.reType)"
            :class="item.reType == reType ? 'reType' : ''"
            v-for="item in typeList"
            :key="item.name"
          >
            {{ item.name }}
          </div>
        </div>
      </div>

      <div class="refresh">
        <i class="icon"></i>
        <div
          class="refresh_txt"
          @click="
            list = [];
            total = 0;
            getOrderList();
          "
        >
          刷新
        </div>
      </div>
    </div>

    <div
      class="list_box"
      v-show="list.length != 0"
      v-for="(item, index) in list"
      :key="index"
    >
      <div class="data_top_box">
        <div class="serial_box">
          备注:
          <span class="color_txt"> {{ item.orderNumber }} </span>
        </div>
        <div class="place_date_box">下单时间</div>
        <div class="number_box"></div>
        <div class="money_box">券</div>
        <div class="txt">{{ item.payStatus == 1 ? "未完成" : "已完成" }}</div>
      </div>
      <div class="data_bottom_box">
        <div class="list">
          <div class="item_box_left">
            <div class="img_name_box">
              <!-- <div>{{ $forceUpdate(item1.productFiles["fileUrl"]) }}</div> -->
              <div class="item_name">
                {{ item.remark }} <br />
                <!-- 支付类型:{{ item.payType == 1 ? "微信" : "支付宝" }} -->
              </div>
            </div>

            <div class="item_date">{{ item.createTime }}</div>
            <div class="item_sum"></div>
            <div class="item_money">{{ item.quantity }} 券</div>
          </div>
        </div>

        <div class="item_box_right">
          <div class="order_money">{{ item.quantity }} 券</div>
          <!-- <div class="order_delete" @click="prompt(item.orderNumber)">删除</div> -->
        </div>
      </div>
    </div>

    <div v-show="total > 10" class="pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        prev-text="上一页"
        next-text="下一页"
        :current-page="page"
        @current-change="currentChange"
        :total="total"
      >
      </el-pagination>
    </div>

    <div v-show="list.length == 0" class="noOrder">
      <img :src="require('assets/images/no-shop.png')" alt="" />
      暂无订单
    </div>
  </div>
</template>

<script>
//例如：import  from '';
import { findIdiomExpend } from "api/copyrightService";

export default {
  name: "OrderIdiomS",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      orderlist: [],
      page: 1,
      list: [],
      total: 0,
      reType: 2,
      typeList: [
        {
          name: "支出",
          reType: 2,
        },
        {
          name: "收入",
          reType: 1,
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    reTypeCLick(val) {
      this.reType = val;
      this.getOrderList();
    },
    async getOrderList() {
      const data = {
        page: this.page,
        limit: 10,
        reType: this.reType,
        sort: "create_time desc",
      };

      const res = await findIdiomExpend(data);

      if (res.code == 200) {
        this.list = res.data.data;
        this.total = res.data.total;
      }
    },

    currentChange(cur) {
      this.page = cur;
      this.getOrderList();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getOrderList();
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.OrderIdiomS {
  .title_box {
    padding: 0 20px;
    margin: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title_txt {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      display: flex;
      align-items: center;
      color: #333333;

      .type {
        margin-left: 20px;
        display: flex;
        align-items: center;
        .item {
          cursor: pointer;
          margin-right: 20px;
          padding: 8px 12px;
        }

        .reType {
          background: linear-gradient(90deg, #ff6500, #ff9c00);
          border-radius: 1rem;
          color: #fff;
        }
      }
    }

    .refresh {
      display: flex;
      align-items: center;
      // justify-content: space-between;
      cursor: pointer;
      .icon {
        width: 15px;
        height: 13px;
        @include backgroundGroup("~assets/images/refresh-gray.png");
        margin-right: 10px;
      }
      .refresh_txt {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
      }
    }
  }

  .list_box {
    .data_top_box {
      display: flex;
      background-color: #f8f8f8;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 800;
      padding: 10px 0;

      .serial_box {
        padding: 0 0 0 20px;
        flex: 25%;
        .color_txt {
          color: #ff6900;
        }
      }

      .place_date_box {
        flex: 20%;
        text-align: center;
      }

      .number_box {
        flex: 8%;
        padding-left: 21px;
        box-sizing: border-box;
      }

      .money_box {
        flex: 10%;
        text-align: center;
      }

      .txt {
        flex: 20%;
        text-align: center;
      }
    }

    .data_bottom_box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      padding: 20px;

      .list {
        flex: 1;
      }
      .item_box_left {
        padding: 20px 0;
        flex: 1;
        display: flex;
        border-bottom: 1px solid #ccc;

        &:last-child {
          border-bottom: none;
        }

        .img_name_box {
          flex: 28%;
          display: flex;
          flex-direction: row;
          .item_img {
            width: 80px;
            height: 80px;
            margin-right: 20px;
          }
          .item_name {
            line-height: 30px;
          }
        }

        .item_date {
          flex: 12%;
          text-align: center;
        }

        .item_sum {
          flex: 2%;
          text-align: center;
        }

        .item_money {
          flex: 11%;
          text-align: center;
        }
      }

      .item_box_right {
        // width: 205px;
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        .order_money {
          margin: 5px 0;
          color: #eb441e;
          font-weight: 800;
        }

        .order_delete,
        .order_collection,
        .order_comments {
          cursor: pointer;
          margin: 5px 0;
        }
      }
    }
  }

  .pagination {
    margin: 40px;

    .el-pagination {
      text-align: center;
    }
  }

  .noOrder {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin: 220px 0;

    img {
      margin-bottom: 20px;
    }
  }
}
</style>
