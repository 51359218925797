<template>
  <div class="OrderAIGCS">
    <div class="tab_box">
      <div
        class="tab_item"
        :class="index == tabIndex ? 'active' : ''"
        @click="
          tabIndex = index;
          getlist(item.id);
        "
        v-for="(item, index) in tabList"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>

    <div class="list_box">
      <div class="list_item" v-for="item in list" :key="item.id">
        <div class="top">
          <img class="img" src="~assets/writtenAi/youwriytt.png" alt="" />

          <div class="right_box">
            <div class="content">
              <div class="title">{{ item.topic }}</div>
              <div class="text">{{ item.content }}</div>
            </div>
          </div>
        </div>

        <div class="bottom">
          <div class="price">
            <span> </span><span class="span"> {{ item.price }}</span> 券/篇起
          </div>
          <div v-if="item.state == 1" class="btn" @click="viewCLick(item)">
            查看
          </div>
        </div>

        <img
          class="icon"
          :src="
            item.state == 1
              ? require('assets/images/yi.png')
              : require('assets/images/wei.png')
          "
        />
      </div>

      <div v-show="list.length == 0" class="noOrder">
        <img :src="require('assets/images/no-shop.png')" alt="" />
        暂无数据
      </div>
    </div>

    <el-dialog
      title="AIGC稿"
      :visible.sync="dialogVisible"
      width="750px"
      :before-close="handleClose"
    >
      <div class="dialogVisible_content">
        <div class="text" v-html="content"></div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
//例如：import  from '';
import { logUserDisplay } from "api/Continuation";
import qs from "qs";
export default {
  name: "OrderAIGCS",
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      tabIndex: 0,
      tabList: [
        {
          id: 1,
          name: "全部",
        },
        {
          id: 2,
          name: "已生成",
        },
        {
          id: 3,
          name: "未生成",
        },
      ],
      list: [],
      dialogVisible: false,
      content: "",
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    viewCLick(item) {
      this.dialogVisible = true;
      this.content = item.content;
    },
    handleClose() {
      this.content = "";
      this.dialogVisible = false;
    },
    async getlist(type) {
      let res = await logUserDisplay(qs.stringify({ type }));
      // console.log(res);
      if (res.code == 200) {
        this.list = res.data || [];
      }
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getlist(1);
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style lang="scss" scoped>
.OrderAIGCS {
  padding: 30px;

  .dialogVisible_content {
    .text {
      line-height: 1.8;
      height: 450px;
      overflow: auto;
      text-align: justify;
    }
  }

  .tab_box {
    display: flex;
    align-items: center;

    .tab_item {
      font-size: 14px;
      margin: 0 10px;
      padding: 5px 10px;
      cursor: pointer;
    }

    .active {
      background: linear-gradient(90deg, #ff6500, #ff9c00);
      border-radius: 12px;
      color: #fff;
    }
  }

  .list_box {
    margin-top: 30px;
    position: relative;
    // padding: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    min-height: 55vh;

    .noOrder {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      img {
        margin-bottom: 20px;
      }
    }
    .list_item {
      width: 46%;
      margin-bottom: 30px;
      box-shadow: 0px 3px 15px 0px rgba(183, 194, 215, 0.29);
      border-radius: 20px;
      padding: 22px 13px 13px 13px;
      position: relative;

      .icon {
        position: absolute;
        right: 0;
        top: 20px;
        width: 50px;
      }

      .top {
        display: flex;
        align-items: center;
        .img {
          width: 50px;

          margin-right: 20px;
        }

        .right_box {
          flex: 1;
          justify-content: space-between;
          align-items: center;
          display: flex;
          .content {
            .title {
              font-size: 18px;
              margin-bottom: 9px;
              font-weight: bold;
              text-justify: distribute-all-lines;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 1;
              overflow: hidden;
            }

            .text {
              font-size: 12px;
              color: #9fa9bb;
              text-justify: distribute-all-lines;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            }
          }
        }
      }

      .bottom {
        margin-top: 10px;
        border-top: 1px solid #f0f2f5;
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .price {
          color: #999999;
          font-size: 12px;
          span {
            color: #e8232c;
          }

          .span {
            font-weight: bold;
            font-size: 19px;
          }
        }

        .btn {
          width: 66px;
          height: 29px;
          font-size: 12px;
          text-align: center;
          line-height: 29px;
          cursor: pointer;
          border: 2px solid #cbd6ff;
          color: #4587ff;
          border-radius: 14px;
        }
      }
    }
  }
}
</style>
